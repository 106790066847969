import React from 'react';
import { Alert, Box, CircularProgress, FormControl, InputAdornment, List, ListItem, ListItemButton, ListItemText, Popover } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

function Search({ parcelshops, map, filtered, setFiltered, ...rest }) {
    const [search, setSearch] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [places, setPlaces] = React.useState([]);
    const [anchorEl, setAnchorEl] = React.useState(false);

    React.useEffect(() => {
        if (search !== '') {
            setLoading(true);
        }
    }, [search])
    
    React.useEffect(() => {
        if (search !== '') {
            const delayDebounceFn = setTimeout(() => {
                let tmpSearch = search.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                tmpSearch = tmpSearch.toLowerCase();
                let tmpSearched = [];
                for (const parcelshop of parcelshops) {
                    if (parcelshop.search_string.includes(tmpSearch)) {
                        tmpSearched.push(parcelshop);
                    }
                    if (tmpSearched.length === 100) {
                        break;
                    }
                }
                if (tmpSearched.length === 0) {
                    fetch(`https://photon.komoot.io/api/?q=${tmpSearch}`).then((res) => res.json()).then((data)=>{
                        setPlaces(data.features);
                        setLoading(false);
                    }).catch(e => {
                        setLoading(false);
                        return;
                    })
                } else {
                    setPlaces([]);
                    setLoading(false);
                }
                setFiltered(tmpSearched);
            }, 1000)
    
            return () => clearTimeout(delayDebounceFn)
        }
    }, [search, parcelshops, setFiltered])

    const handlePlacesClose = () => {
        setPlaces([]);
        setAnchorEl(false);
    };

    const onItemClick = (data) => {
        map.flyTo([data.geometry.coordinates[1], data.geometry.coordinates[0]], 14);
        setAnchorEl(null);
        setPlaces([]);
    };

    return (
        <React.Fragment>
            <Box px={2}>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-search">Hľadať</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-search"
                        value={search}
                        onChange={(e) => {
                            setSearch(e.target.value)
                            if (!anchorEl) {
                                setAnchorEl(e.currentTarget)
                            }
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={null}
                                >
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Hľadať"
                    />
                </FormControl>

                <Popover
                    open={places.length !== 0}
                    anchorEl={anchorEl}
                    onClose={handlePlacesClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <List dense={true} className={'list'}>
                    {
                        places.map((v, key) => {
                            if (!['highway', 'village', 'place'].includes(v.properties.osm_key)) {
                                return null;
                            }

                            return (
                                <ListItem key={key} className={'listItem'} onClick={() => onItemClick(v)} >
                                    <ListItemButton>
                                        <ListItemText
                                            primary={`${v.properties.name} ${v.properties.city} ${v.properties.postcode}`}
                                            primaryTypographyProps={{ fontWeight: "bold" }}
                                            secondary={`${v.properties.countrycode}`}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            ) 
                        } )
                    }
                    </List>
                </Popover>
            </Box>
            {
                loading &&
                <Box p={2} textAlign="center">
                    <CircularProgress />
                </Box>
            }
            {
                !loading && search !== '' && places.length === 0 && filtered.length === 0 &&
                <Box p={2} textAlign="center">
                    <Alert severity="info">Nič sa nenašlo</Alert>
                </Box>
            }
        </React.Fragment>
    )
}

export default Search;
