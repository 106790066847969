import * as React from "react";
import { LatLng } from "leaflet";
import L from 'leaflet';
import { MapContainer, TileLayer, Popup, Marker, useMapEvents } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import useSupercluster from "use-supercluster";
import PopupContent from './PopupContent';

export default function Map({ shipper, parcelshops, mapRef, ...rest}) {

    const [markers, setMarkers] = React.useState([]);
    const [map, setMap] = React.useState(null);

    const [bounds, setBounds] = React.useState(null);
    const [zoom, setZoom] = React.useState(13);
    const [defLocationWasSet, setDefLocationWasSet] = React.useState(false);

    const { clusters } = useSupercluster({
        points: markers,
        bounds: bounds,
        zoom: zoom,
        options: { radius: 70, maxZoom: 20 }
    });

    mapRef.current = map;

    const updateMap = React.useCallback(() => {
        if (map) {
            const b = map.getBounds();
            setBounds([
                b.getSouthWest().lng,
                b.getSouthWest().lat,
                b.getNorthEast().lng,
                b.getNorthEast().lat
            ]);
            setZoom(map.getZoom());
        }
    }, [map]);

    React.useEffect(() => {
        if (map) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;
                    map.flyTo([latitude, longitude], 14)
                });
                setDefLocationWasSet(true);
            }
        }
    }, [map, defLocationWasSet]);
    
    React.useEffect(() => {
        let tmpData = [];
        for (const parcelshop of parcelshops) {
            tmpData.push(
                {
                    type: "shipper",
                    properties: { cluster: false, data: parcelshop },
                    geometry: {
                        type: "Point",
                        coordinates: [
                            parseFloat(parcelshop.longitude),
                            parseFloat(parcelshop.latitude)
                        ]
                    }
                }
            );
        }
        setMarkers(tmpData);
    }, [shipper, parcelshops]);

    React.useEffect(() => {
        if (map) {
            updateMap(map)
        }
    }, [map, markers, updateMap]);

    function Events() {
        useMapEvents({
            moveend(map) {
                updateMap(map)
            },
        })

        return null;
    }

    const getIconByShipper = (multiple = false) => {
        switch (shipper) {
            case parseInt(process.env.REACT_APP_SHIPPER_GLS_ID):
                return `/gls-icon${multiple ? '-multiple' : ''}.svg`;
            case parseInt(process.env.REACT_APP_SHIPPER_SPS_ID):
                return `/sps-icon${multiple ? '-multiple' : ''}.svg`;
            case parseInt(process.env.REACT_APP_SHIPPER_PACKETA_ID):
                return `/packeta-icon${multiple ? '-multiple' : ''}.svg`;
            case parseInt(process.env.REACT_APP_SHIPPER_DPD_ID):
                return `/dpd-icon${multiple ? '-multiple' : ''}.svg`;
        
            default:
                break;
        }
    };

    const getIcon = (pointCount) => {
        if (pointCount > 1) {
            return L.divIcon({
                html: `
                    <div class="cluster-marker cluster-marker-multiple">
                        <img src="${getIconByShipper(true)}" />
                        <span class="cluster-marker-count">
                            ${pointCount}
                        </span>
                    </div>`
            });
            
        }
        return L.divIcon({
            html: `
                <div class="cluster-marker">
                    <img src="${getIconByShipper()}" />
                </div>`
        });
    }

    const markerClick = (e, isCluster) => {
        if (isCluster && map.getZoom() < 15) {
            map.setView(e.latlng, 15);
        } 
        else if (isCluster && map.getZoom() >= 15) {
            map.setView(e.latlng, map.getZoom() + 1);
        }
    }

    return (
        <MapContainer
            center={new LatLng(48.72, 19.445)}
            zoom={9} 
            whenReady={(map) => {
                setMap(map.target);
            }}
            className={'mapContainer'}
            scrollWheelZoom={true}
            style={{ minHeight: 'calc(100vh - 64px)', height: '500px', width: '100%' }}
        >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Events />
            {clusters.map((cluster, key) => {
                const [longitude, latitude] = cluster.geometry.coordinates;
                const {
                    cluster: isCluster,
                    point_count: pointCount,
                    data
                } = cluster.properties;

                if (isCluster) {
                    return (
                        <Marker
                            key={key}
                            position={[latitude, longitude]}
                            icon={getIcon(pointCount)}
                            eventHandlers={{
                                click: (e) => {
                                    markerClick(e, isCluster);
                                },
                            }}
                        />
                    );
                }

                return (
                    <Marker
                        key={key}
                        position={[latitude, longitude]}
                        icon={getIcon(pointCount)}
                        eventHandlers={{
                            click: (e) => {
                                markerClick(e);
                            },
                        }}
                    >
                        <Popup>
                            <PopupContent data={data} />
                        </Popup>
                    </Marker>
                );
            })}
        </MapContainer>
    );
}