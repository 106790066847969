import { Button, Typography } from '@mui/material';
import React from 'react';

function PopupContent({ data, ...rest }) {

    const onSelect = () => {
        window.parent.postMessage(data, "*");
    }

    return (
        <React.Fragment>
            <Typography variant="subtitle1" gutterBottom component="div" fontWeight="bold">
                {data.name}
            </Typography>
            <Typography variant="body2" gutterBottom component="div">
                {data.street}<br />
                {data.city} {data.zip}<br />
                {data.state_code}
            </Typography>
            <Button
                color="info"
                variant={"contained"}
                onClick={onSelect}
                fullWidth
            >
                Vybrať
            </Button>
        </React.Fragment>
    )
}

export default PopupContent;
