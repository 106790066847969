import './App.css';
import Map from './Map';
import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, Grid, IconButton } from '@mui/material';
/* import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select'; */
import Search from './Search';
import ListItemButton from '@mui/material/ListItemButton';
import L from 'leaflet';
import PopupContent from './PopupContent';
import ReactDOM from 'react-dom';
import { List, ListItem, ListItemText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const theme = createTheme({
    palette: {
        primary: {
            main: '#ed4f2f',
        },
        secondary: {
            main: '#46385b',
        },
    },
});

function App() {
    const query = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(query.entries());
    const [shipper] = React.useState(parseInt(params.shipper_id));
    const [parcelshops, setParcelshops] = React.useState([]);
    const mapRef = React.useRef();
    const [filtered, setFiltered] = React.useState([]);

    React.useEffect(() => {
        if (shipper) {
            fetch(`${process.env.REACT_APP_API_URL}/api/all/parcelshop/list/${shipper}`)
                .then(response => response.json())
                .then(data => {
                    setParcelshops(data.map(v => {
                        v.search_string = (`${v.name} ${v.city} ${v.street} ${v.zip}`).normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                        v.search_string = v.search_string.toLowerCase();
                        return v;
                    }));
                });
        }
    }, [shipper]);

    const onItemClick = (data) => {
        mapRef.current.setView([data.latitude, data.longitude], 17);

        let div = document.createElement('div');
        ReactDOM.render(
            <PopupContent data={data} />,
            div
        );

        L.popup()
            .setLatLng([data.latitude, data.longitude])
            .setContent(div)
            .openOn(mapRef.current);
    }

    const onClose = () => {
        window.parent.postMessage("close", "*");
    }

    return (
        <ThemeProvider theme={theme}>
            <AppBar position="static" className={'appBar'}>
                <Toolbar>
                    <Typography variant="h6" color="inherit" className={'logo'} noWrap>
                        Vyberte si miesto výzdvihu
                    </Typography>
                    <IconButton onClick={onClose} >
                        <CloseIcon className={'closeButton'}/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Grid container spacing={0}>
                <Grid className={'leftBar'}>
                    {/* <Box p={2}>
                        <Select
                            value={shipper}
                            onChange={(e) => setShipper(e.target.value)}
                            fullWidth
                        >
                            <MenuItem value={1}>GLS</MenuItem>
                            <MenuItem value={2}>SPS</MenuItem>
                            <MenuItem value={3}>Packeta</MenuItem>
                        </Select>
                    </Box> */}
                    <Box pt={2} />
                    <Search parcelshops={parcelshops} map={mapRef.current} filtered={filtered} setFiltered={setFiltered} />
                    <List dense={true} className={'list'}>
                        {
                            filtered.map((v, key) =>
                                <ListItem key={key} className={'listItem'} onClick={() => onItemClick(v)} >
                                    <ListItemButton>
                                        <ListItemText
                                            primary={`${v.city} ${v.street}`}
                                            primaryTypographyProps={{ fontWeight: "bold" }}
                                            secondary={`${v.name}`}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            )
                        }
                    </List>
                </Grid>
                <Grid item xs={12} sm>
                    <Map shipper={shipper} parcelshops={parcelshops} mapRef={mapRef} />
                </Grid>
            </Grid>
        </ThemeProvider>
    )
}

export default App;
